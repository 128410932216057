<template>
  <div>
    <div class="course-head">
      <el-row :gutter="20">
        <el-col
          :xs="4"
          :sm="2"
          :md="2"
          :lg="2"
          :xl="2"
          class="course-head-title"
        >
          <router-link to="/courses/my-courses/"
            ><el-button type="primary" icon="el-icon-arrow-left"></el-button
          ></router-link>
        </el-col>
        <el-col
          :xs="20"
          :sm="11"
          :md="11"
          :lg="11"
          :xl="11"
          class="course-head-title"
        >
          <h1>{{ course.course_name }}</h1>
        </el-col>
        <el-col
          :xs="20"
          :sm="7"
          :md="7"
          :lg="7"
          :xl="7"
          class="course-head-progress"
        >
          <el-progress
            type="circle"
            :percentage="percentage"
            :color="colors"
            :width="60"
          ></el-progress>
        </el-col>
        <el-col
          :xs="4"
          :sm="2"
          :md="2"
          :lg="2"
          :xl="2"
          class="course-head-title"
        >
          <el-button v-if="showNext" type="primary" @click="nextContent">
            {{ $t("courses.next") }} <i class="el-icon-arrow-right"></i>
          </el-button>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col :xs="24" :sm="6">
        <div class="playlistMenu">
          <el-collapse accordion v-model="activeMenu">
            <el-collapse-item
              class="play"
              v-for="(play, play_index) in course.playlist"
              :key="play.following_id"
              :name="play.following_id"
            >
              <template slot="title">
                <h3 v-html="getTitle(play.order, play.name)"></h3>
              </template>
              <div v-if="play.children !== '0'">
                <div
                  class="playChild"
                  :class="{ playChildActive: child.active }"
                  v-for="(child, child_index) in play.children"
                  :key="child.following_id"
                >
                  <el-row>
                    <el-col
                      :xs="2"
                      :sm="3"
                      :md="4"
                      :lg="3"
                      :xl="2"
                      style="text-align: center"
                    >
                      <el-checkbox
                        class="childTitle"
                        @change="
                          updateProgress(child.following_id, child.is_completed)
                        "
                        v-model="child.is_completed"
                      >
                      </el-checkbox>
                    </el-col>
                    <el-col :xs="22" :sm="21" :md="20" :lg="21" :xl="22">
                      <div
                        @click="showContent(child, play_index, child_index)"
                        class="childContent"
                      >
                        <span>{{ child_index + 1 }}. {{ child.name }}</span>
                        <div v-if="child.children != 0" class="childFiles">
                          <el-dropdown>
                            <el-button type="primary" size="small" plain>
                              <i class="el-icon-folder-opened"></i> Resources
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item
                                v-for="item in child.children"
                                :key="item.following_id"
                              >
                                <el-button
                                  type="primary"
                                  size="mini"
                                  @click="downloadFile(item.url)"
                                >
                                  <i class="el-icon-document"></i>
                                  {{ item.name }}
                                </el-button>
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-col>
      <el-col :xs="24" :sm="18">
        <div v-if="content.active" class="playlist">
          <div class="playlistShow" v-loading="loading">
            <div v-if="content.type == 'type_video'">
              <iframe
                width="100%"
                height="500"
                :src="getVideo(content.url)"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              >
              </iframe>
            </div>
            <div v-else-if="content.type == 'type_practice'"></div>
            <div v-else-if="content.type == 'type_file'">
              <iframe :src="content.url" width="100%" height="500px"></iframe>
            </div>
          </div>
          <div class="playDetails">
            <el-tabs v-model="activeName">
              <!-- <el-tab-pane
                :label="$t('courses.course_description')"
                name="course"
              >
                {{ course.description }}
              </el-tab-pane> -->
              <el-tab-pane
                :label="$t('courses.chapter_description')"
                name="play"
              >
                {{ content.description }}
              </el-tab-pane>
              <el-tab-pane
                :label="$t('courses.chapter_resources')"
                name="content"
                :disabled="content.children == '0' || content.children == []"
              >
                <div class="playchild-content">
                  <div
                    v-for="item in content.children"
                    :key="item.following_id"
                  >
                    <el-button
                      type="primary"
                      size="mini"
                      @click="downloadFile(item.url)"
                    >
                      <i class="el-icon-document"></i>
                      {{ item.name }}
                    </el-button>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div v-else class="playlist">
          <h1>Welcome!</h1>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import onlineCourseApi from "@/apis/onlineCourse";
import onlineCourseMixin from "@/mixins/onlineCourse.js";

export default {
  name: "course",

  components: {},

  mixins: [onlineCourseMixin],

  props: [],
  data() {
    return {
      content: {},
      colors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 60 },
        { color: "#1989fa", percentage: 99 },
        { color: "#5cb87a", percentage: 100 }
      ],
      course: {},
      title: "",
      loading: false,
      activeName: "play",
      activeMenu: "",
      percentage: 0,
      activePlayIndex: 0,
      activeChildIndex: 0,
      nextPlayIndex: 0,
      nextChildIndex: 0,
      showNext: true
    };
  },
  computed: {
    ...mapGetters("user", ["langValue"]),
    courseId() {
      return this.$route.query.id;
    }
  },
  watch: {
    langValue() {
      this.resetData();
    }
  },

  async mounted() {
    const data = await onlineCourseApi.singleCourse({
      course_id: this.courseId,
      lang: this.langValue
    });
    this.course = data[0];
    this.resetData(this.course);
  },
  methods: {
    resetData(course) {
      course = this.course;
      let activePlayIndex = 0;
      let activeChildIndex = 0;
      for (let play_index in course.playlist) {
        let children = course.playlist[play_index].children;
        if (children != "0" && children.length > 0) {
          for (let child_index in children) {
            if (children[child_index].is_completed == 1) {
              children[child_index].is_completed = true;
              this.activeMenu = course.playlist[play_index].following_id;
              activePlayIndex = play_index;
              activeChildIndex = child_index;
            } else {
              children[child_index].is_completed = false;
            }
          }
        }
      }
      let content = course.playlist[activePlayIndex].children[activeChildIndex];
      this.showContent(content, activePlayIndex, activeChildIndex);
      this.lessons_progress();
    },
    showContent(content, activePlayIndex, activeChildIndex) {
      this.showNext = true;
      this.activePlayIndex = activePlayIndex;
      this.activeChildIndex = activeChildIndex;
      this.showNext = this.hasNext();
      for (let play_index in this.course.playlist) {
        if (this.course.playlist[play_index]["children"] != "0") {
          for (let playchild_index in this.course.playlist[play_index][
            "children"
          ]) {
            this.course.playlist[play_index]["children"][
              playchild_index
            ].active = false;
          }
        }
      }
      content.active = true;
      this.content = content;
      if (this.content.children == 0 || this.content.children == []) {
        this.activeName = "play";
      } else {
        this.activeName = "content";
      }
    },
    lessons_progress() {
      let playlist = this.course.playlist;
      let lessons_total = 0;
      let lessons_progress = 0;
      for (let play_index in playlist) {
        let children = playlist[play_index].children;
        if (children !== "0" && children.length > 0) {
          for (let child_index in children) {
            lessons_total++;
            if (children[child_index].is_completed) {
              lessons_progress++;
            }
          }
        }
      }
      this.percentage = Math.round((lessons_progress / lessons_total) * 100);
    },
    // 判断是否是最后一个
    hasNext() {
      let hasNext = true;
      if (
        this.course.playlist[this.activePlayIndex].children[
          this.activeChildIndex + 1
        ] == undefined
      ) {
        if (this.course.playlist[this.activePlayIndex + 1] == undefined) {
          hasNext = false;
        } else {
          if (
            this.course.playlist[this.activePlayIndex + 1].children[0] ==
            undefined
          ) {
            hasNext = false;
          } else {
            this.nextPlayIndex = this.activePlayIndex + 1;
            this.nextChildIndex = 0;
          }
        }
      } else {
        this.nextPlayIndex = this.activePlayIndex;
        this.nextChildIndex = this.activeChildIndex + 1;
      }
      return hasNext;
    },
    nextContent() {
      let content = this.course.playlist[this.nextPlayIndex].children[
        this.nextChildIndex
      ];
      this.showContent(content, this.nextPlayIndex, this.nextChildIndex);
    },
    async updateProgress(item_id, is_completed) {
      console.log(item_id);
      console.log(is_completed);
      if (is_completed) {
        is_completed = 1;
      } else {
        is_completed = 0;
      }
      await onlineCourseApi.courseProgress({
        course_id: this.courseId,
        item_id: item_id,
        is_completed: is_completed
      });
      this.lessons_progress();
    }
  }
};
</script>
<style>
.play > div > .el-collapse-item__header {
  height: auto;
  line-height: normal;
  padding: 10px;
}
.play > div > .el-collapse-item__content {
  padding: 0;
}
</style>
<style scoped>
.course-head {
  padding: 5px 0;
  background: #f8f8f8;
}

.course-head-title {
  text-align: center;
  line-height: 60px;
  vertical-align: middle;
}

.course-head-progress {
  height: 60px;
  text-align: center;
  vertical-align: middle;
}

.playlist {
  border-top: 1px solid #ebeef5;
  border-left: 1px solid #ebeef5;
  height: 800px;
  overflow: auto;
}

.playlistShow {
  min-height: 500px;
}

.playDetails {
  padding: 10px;
}

.playChild {
  padding: 5px 0;
  cursor: pointer;
  background: #f8f8f8;
}

.playChild:hover {
  background: #e6f2f5;
  color: #14171c;
}

.playChildActive {
  background: #42a16a;
  color: #fff;
}
.playChildActive:hover {
  background: #42a16a;
  color: #fff;
}

.childTitle {
  padding: 0 10px;
}

.childContent {
  font-size: 14px;
  font-weight: 400;
}

.childFiles {
  text-align: right;
  padding: 5px;
}

.childContent {
  padding: 0 10px 0px 0;
}

.playchild-content {
  line-height: 26px;
}

.playchild-content a {
  text-decoration: none;
  color: #fff;
}
</style>
